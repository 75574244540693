<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>规则设置</span>
      </div>

      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="物流设置" name="first">
          <el-form style="width: 70%" :model="form1" ref="form1" label-width="180px">
            <el-form-item label="查询类型选择" prop="name">
              <el-radio-group v-model="type">
                <el-radio :label="1">快递鸟</el-radio>
                <el-radio :label="2">快递100</el-radio>
                <el-radio :label="3">阿里云接口</el-radio>
              </el-radio-group>
            </el-form-item>

            <block v-if="type == 3">
              <el-form-item label="阿里云APPCODE" prop="description">
                <el-input
                  v-model="form1.express_ali_appcode"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="电子面单选择" prop="name">
                <el-radio-group v-model="type1">
                  <el-radio :label="1">快递鸟</el-radio>
                  <el-radio :label="2">快递100</el-radio>
                </el-radio-group>
              </el-form-item>
            </block>
            <block v-if="type == 1 || (type == 3 && type1 == 1)">
              <el-form-item label="接口选择" prop="name">
                <el-radio-group v-model="form1.express_kdn_api_type">
                  <el-radio label="0">免费</el-radio>
                  <el-radio label="1">付费</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="快递鸟用户ID" prop="description">
                <el-input v-model="form1.express_kdn_id" autocomplete="off"></el-input>
              </el-form-item>

              <el-form-item label="快递鸟API KEY" prop="layout">
                <el-input
                  v-model="form1.express_kdn_app_key"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </block>
            <block v-if="type == 2 || type1 == 2">
              <el-form-item label="快递100授权KEY" prop="express_kd100_key">
                <el-input v-model="form1.express_kd100_key" autocomplete="off"></el-input>
              </el-form-item>

              <el-form-item label="快递100customer" prop="express_kd100_customer">
                <el-input
                  v-model="form1.express_kd100_customer"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="快递100secret" prop="express_kd100_secret">
                <el-input
                  v-model="form1.express_kd100_secret"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="快递100打印机编码" prop="layout">
                <el-input
                  v-model="form1.express_kd100_printer_code"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </block>
            <el-form-item>
              <el-button type="primary" @click="submit1">保存</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="退货地址" name="second">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>地址列表</span>
              <el-button
                type="success"
                icon="el-icon-plus"
                @click="addAddress"
                v-if="$check(51)"
                style="float: right; position: relative; bottom: 10px"
                >添加</el-button
              >
              <el-button
                type="primary"
                @click="query(1)"
                style="float: right; margin-right: 10px; position: relative; bottom: 10px"
                icon="el-icon-search"
                >查询</el-button
              >
              <el-input
                v-model="filterData.search"
                placeholder="输入关键字搜索"
                clearable
                style="
                  float: right;
                  width: 250px;
                  margin-right: 10px;
                  position: relative;
                  bottom: 10px;
                "
              ></el-input>
            </div>
            <el-table :data="tableData" style="width: 100%">
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column prop="sort" label="收件人姓名"></el-table-column>
              <el-table-column prop="name" label="联系方式"></el-table-column>
              <el-table-column prop="description" label="详细地址"></el-table-column>
              <el-table-column prop="layout" label="备注"></el-table-column>

              <el-table-column label="操作" width="200">
                <template slot-scope="scope">
                  <el-button
                    v-if="$check(52)"
                    size="small"
                    type="primary"
                    @click="edit(scope.row)"
                    >编辑</el-button
                  >

                  <el-button
                    v-if="$check(53)"
                    size="small"
                    type="danger"
                    @click="remove(scope.row.id)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <el-row>
              <el-col :span="24" style="text-align: center; margin-top: 10px">
                <el-pagination
                  background
                  layout="total,prev, pager, next"
                  @current-change="pageChange"
                  :current-page="filterData.page"
                  :total="total"
                ></el-pagination>
              </el-col>
            </el-row>
          </el-card>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <el-dialog center title="添加地址" :visible.sync="dialogFormVisible">
      <el-card>
        <!-- <div slot="header">
          <span>添加分类</span>
        </div> -->
        <el-form :model="form" ref="form" label-width="120px">
          <el-form-item
            label="收件人姓名"
            prop="name"
            :rules="[{ required: true, message: '收件人姓名不能为空' }]"
          >
            <el-input v-model="form.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item
            label="联系方式"
            prop="description"
            :rules="[{ required: true, message: '联系方式不能为空' }]"
          >
            <el-input v-model="form.description" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item
            label="省市区"
            prop="layout"
            :rules="[{ required: true, message: '省市区不能为空' }]"
          >
            <el-cascader
            style="width: 360px; boder-left: none; border-top-left-radius: 0"
            size="large"
            :options="options"
            v-model="form.area"
          >
          </el-cascader>
          </el-form-item>
          <el-form-item
            label="详细地址"
            prop="layout"
            :rules="[{ required: true, message: '详细地址不能为空' }]"
          >
            <el-input
              type="textarea"
              :rows="3"
              v-model="form.layout"
              autocomplete="off"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="备注"
            prop="layout"
            :rules="[{ required: true, message: '备注不能为空' }]"
          >
            <el-input v-model="form.layout" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
      </el-card>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { regionData } from "element-china-area-data";
export default {
  data() {
    return {
      type: 1,
      type1: 1,
      options: regionData,
      activeName: "first",
      activeName1: "kdn",
      total: 0,
      rule: [],
      filterData: { page: 1 },
      tableData: [],
      tableData1: [],
      dialogFormVisible: false,
      form: {},
      form1: {},
      product_id: 0,
      products: [],
    };
  },

  created() {
    this.query1();
  },

  methods: {
    query1() {
      this.$http
        .post("/admin/config/get", [
          "express_ali_appcode",
          "express_kdn_api_type",
          "express_kdn_id",
          "express_kdn_app_key",
          "express_kd100_key",
          "express_kd100_customer",
          "express_kd100_secret",
          "express_kd100_printer_code",
        ])
        .then((res) => {
          this.form1 = res.data;
        })
        .catch(() => {});
    },

    addAddress() {
      this.form = {};
      this.dialogFormVisible = true;
    },

    submit1() {
      this.$refs.form1.validate((valid) => {
        if (valid) {
          this.$http.post("/admin/config/save", this.form1).then(() => {
            this.$message({
              message: "操作成功",
              type: "success",
            });
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped></style>
